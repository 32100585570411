import React, { CSSProperties } from "react";

import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { cva } from "class-variance-authority";
import { flag } from "country-emoji";
import { isAfter } from "date-fns";
import { useTranslation } from "react-i18next";
import { HiDocumentText, HiExclamation } from "react-icons/hi";
import { HiOutlineUsers, HiTicket } from "react-icons/hi2";
import {
  LuCalendarCheck2,
  LuCalendarClock,
  LuCalendarPlus,
  LuCalendarX2,
} from "react-icons/lu";
import { TbHanger } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

import { AppointmentCell } from "@calendar/helpers/calendar-column";
import {
  getAppointmentFormatIcon,
  getAppointmentTypeLabel,
} from "@calendar/helpers/formatters";
import { COLUMN_WIDTH, ROW_HEIGHT } from "@calendar/style";
import { Calendar } from "@calendar/types";
import HoverCard from "@components/data-display/HoverCard";
import Tag from "@components/data-display/Tag";
import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import { DropDown } from "@components/feedback/DropdownMenu";
import { useIsMobile } from "@components/layout/Breakpoint";
import { formatDatetimeIntervalAtTimezone } from "@helpers/Date";
import { AccountsStatusColors } from "@models/old/Account";
import { AppointmentTypeEnum } from "@models/types/enums";
import useAppointmentCheckin from "@services/api/appointments/checkin";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { FEATURE_APPOINTMENT_REPORTS } from "@services/feature-flags";
import getAppointmentVirtualLink from "@shared/helpers/appointment";
import { fullName } from "@shared/helpers/formatters";
import {
  translateAccountStatus,
  translateAppointmentFormatWithApp,
} from "@shared/helpers/translater";

const slotStyle = cva("py-1 px-2 rounded-md h-full cursor-pointer relative", {
  variants: {
    status: {
      PLANNED: "",
      BUYER_ARRIVED: "border-4 border-green-500 border-dashed",
      ONGOING: "border-4 border-green-500",
      DONE: "opacity-70",
      CANCELLED: "opacity-70 border-4 border-red-500",
    },
    isDragging: {
      true: "shadow-lg z-[1000]",
      false: "z-[1]",
    },
  },
});

interface AppointmentProps {
  appointmentCell: AppointmentCell;
  onClick: (appointment: Calendar.Appointment) => void;
}

function AppointmentSlot(props: AppointmentProps) {
  const { appointmentCell, onClick } = props;
  const { appointment, rowSpans } = appointmentCell;

  const {
    id,
    account,
    type,
    collection,
    format,
    startTime,
    endTime,
    showroom: { timezone },
    portfolios = [],
  } = appointment;

  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();
  const checkinMutation = useAppointmentCheckin({ organizationId });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const color = portfolios.length === 1 ? portfolios[0].color : "#FCD8B6";

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: `${id}`,
    });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    backgroundColor: color,
    border: "1px solid #00000",
    width: `${COLUMN_WIDTH}rem`,
    height: `${ROW_HEIGHT * (rowSpans === 1 ? rowSpans : rowSpans)}rem`,
    position: "absolute",
  };

  const isMeetingReportFullyFilled =
    appointment.meetingReport !== null &&
    appointment.meetingReport.otb !== null &&
    appointment.meetingReport.actualBudget !== null;

  const appointmentVirtualLink = getAppointmentVirtualLink(appointment);

  const isLater = isAfter(
    appointment.startTime.toLocalDate(appointment.showroom.timezone),
    new Date(),
  );
  console.log(
    appointment.startTime.toLocalDate(appointment.showroom.timezone),
    new Date(),
    isLater,
  );

  const slotClasses = slotStyle({
    status: appointment.status,
    isDragging,
  });

  return (
    <HoverCard
      placement="right-start"
      renderIf={!isMobile && !isDragging}
      fallbackProp="button"
      button={
        <div className="bg-primaryLightestGrey w-full h-full">
          <div
            data-testid={appointment.id}
            className={slotClasses}
            ref={setNodeRef}
            style={style}
            {...listeners}
            {...attributes}
          >
            <div
              className="overflow-hidden h-full"
              aria-label={t("Calendar.AppointmentSlot.slot-label", {
                name: appointment.account.name,
              })}
              onClick={() => (isLater ? onClick(appointment) : null)}
              onKeyUp={(e) => {
                if (e.key === "Enter" && isLater) {
                  onClick(appointment);
                }
              }}
              role="button"
              tabIndex={0}
            >
              <div className="flex flex-col h-full justify-between">
                <div>
                  <div className="flex text-base justify-between items-center">
                    <div
                      data-testid="appointment-account-name"
                      className="font-medium truncate"
                    >
                      {account.name}
                    </div>
                    {getAppointmentFormatIcon(format)}
                  </div>
                  {rowSpans > 1 && (
                    <div className="text-xs leading-5 font-medium truncate">
                      {type === AppointmentTypeEnum.BUYING_APPOINTMENT &&
                        `${getAppointmentTypeLabel(type)} - ${
                          collection ? collection.name : "unknown"
                        }`}
                      {type !== AppointmentTypeEnum.BUYING_APPOINTMENT &&
                        getAppointmentTypeLabel(type)}
                    </div>
                  )}
                </div>
                {rowSpans > 2 && (
                  <div className="text-xs text-gray-600">
                    {startTime.formatTimeAtTimezone(timezone)}
                    {" - "}
                    {endTime.formatTimeAtTimezone(timezone)}
                  </div>
                )}
              </div>
            </div>
            {appointment.warnings && appointment.warnings.length > 0 && (
              <div
                data-testid="warning-icon"
                className="absolute -top-2 -left-2 bg-primaryRed text-white rounded-full p-1"
              >
                <HiExclamation className="w-4 h-4" />
              </div>
            )}

            <div className="absolute flex items-center bottom-2 right-2 gap-2">
              <DropDown
                triggerType="none"
                items={[
                  {
                    content: (
                      <>
                        <LuCalendarClock className="w-4 h-4" />
                        Soft Check-in
                      </>
                    ),
                    onSelect: () =>
                      checkinMutation.mutateAsync({
                        appointmentId: appointment.id,
                        method: "soft-check-in",
                      }),
                  },
                  {
                    content: (
                      <>
                        <LuCalendarPlus />
                        Check-in
                      </>
                    ),
                    onSelect: () =>
                      checkinMutation.mutateAsync({
                        appointmentId: appointment.id,
                        method: "check-in",
                      }),
                  },
                  {
                    content: (
                      <>
                        <LuCalendarCheck2 />
                        Check-out
                      </>
                    ),
                    onSelect: () =>
                      checkinMutation.mutateAsync({
                        appointmentId: appointment.id,
                        method: "check-out",
                      }),
                  },
                  {
                    content: (
                      <>
                        <LuCalendarX2 />
                        Cancelled
                      </>
                    ),
                    onSelect: () =>
                      checkinMutation.mutateAsync({
                        appointmentId: appointment.id,
                        method: "cancel",
                      }),
                  },
                ]}
                trigger={
                  <Tooltip content="Checkin actions" placement="top">
                    <Button
                      className="flex rounded-full bg-primaryLightestGrey p-1 items-center justify-center fill-primaryDarkGrey hover:bg-primaryLightGrey"
                      theme="NONE"
                    >
                      <HiTicket className="w-4 h-4" />
                    </Button>
                  </Tooltip>
                }
              />
              {FEATURE_APPOINTMENT_REPORTS && (
                <Button
                  className="flex rounded-full bg-primaryLightestGrey p-1 items-center justify-center fill-primaryDarkGrey hover:bg-primaryLightGrey"
                  theme="NONE"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate(
                      `/crm/accounts/${account.id}/meeting-report/${id}`,
                    );
                  }}
                >
                  {isMeetingReportFullyFilled && (
                    <HiDocumentText className="text-statusGreenDark" />
                  )}
                  {!isMeetingReportFullyFilled && (
                    <HiDocumentText className="text-primaryDarkGrey" />
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      }
    >
      <div className="w-fit max-w-[500px]">
        <div className="p-4 border-b border-primaryLightGrey">
          <div className="flex items-center gap-2">
            <span className="text-lg font-bold">{account.name}</span>
            <Tag className="inline">
              {flag(appointment.showroom.countryCode)}{" "}
              {appointment.showroom.city}
            </Tag>
          </div>
          <p className="flex items-center gap-2">
            <span>
              {formatDatetimeIntervalAtTimezone(
                appointment.startTime,
                appointment.endTime,
                timezone,
                "PPP",
              )}
            </span>
            {" | "}
            <span>{getAppointmentFormatIcon(format)}</span>
            <span>
              {t(
                translateAppointmentFormatWithApp(
                  format,
                  appointment.virtualMeetingApp,
                ),
              )}
            </span>
          </p>
        </div>
        <div className="p-4 flex flex-col gap-3">
          {appointment.collection ? (
            <div className="flex items-center gap-2">
              <TbHanger />
              <span className="whitespace-nowrap">
                {t("Calendar.AppointmentSlot.collection")}
              </span>
              <span>{appointment.collection.name} </span>
              <Tag type="dotted" theme={AccountsStatusColors[account.status]}>
                {t(translateAccountStatus(account.status))}
              </Tag>
            </div>
          ) : (
            <p className="flex items-center gap-2">
              <TbHanger />
              <span className="whitespace-nowrap">
                {t("Calendar.AppointmentSlot.type")}
              </span>
              <span>{t(`Common.appointment-type.${appointment.type}`)} </span>
              <Tag type="dotted" theme={AccountsStatusColors[account.status]}>
                {t(translateAccountStatus(account.status))}
              </Tag>
            </p>
          )}
          <p className="flex items-baseline gap-2">
            <HiOutlineUsers className="relative top-1" />
            <span className="whitespace-nowrap">
              {t("Calendar.AppointmentSlot.attendees")}
            </span>
            <span>
              {appointment.attendees.map((a) => fullName(a)).join(", ")}
            </span>
          </p>
          {format === "VIRTUAL" && (
            <p>
              <Button
                renderIf={!!appointmentVirtualLink}
                theme="PRIMARY"
                className="w-full items-center justify-center"
                onClick={() => {
                  if (appointmentVirtualLink) {
                    window.open(appointmentVirtualLink);
                  }
                }}
              >
                <span>{getAppointmentFormatIcon(format)}</span>
                {t("Calendar.AppointmentSlot.virtual-link", {
                  tool: t(
                    `Common.virtual-tool.${appointment.virtualMeetingApp}`,
                  ),
                })}
              </Button>
            </p>
          )}
        </div>
        {appointment.warnings && appointment.warnings.length > 0 && (
          <div
            data-testid="warning-icon"
            className="absolute -top-2 -left-2 bg-primaryRed text-white rounded-full p-1"
          >
            <HiExclamation className="w-4 h-4" />
          </div>
        )}
      </div>
    </HoverCard>
  );
}

export default AppointmentSlot;
